<template>
  <page-header-wrapper>
    <template slot="extra">
      <a-button @click="reback" type="primary">
        <a-icon type="rollback" />返回
      </a-button>
    </template>
    <a-card :bordered="false">
      <div class="table-page-search-wrapper">
        <div>
          <div class="title">标题</div>
          <div class="main">
            <a-input v-model="form.title" placeholder="请输入标题" />
          </div>
        </div>
        <div>
          <div class="title">上传文件</div>
          <div class="main">
            <div style="width:280px;">
              <a-upload-dragger name="file" accept=".pdf" :action="imgUpload" @change="handleChange" :fileList="fileList" :data="dataObj" :headers="headers">
                <p class="ant-upload-drag-icon"><a-icon type="inbox" /></p>
                <p class="ant-upload-text">
                  点击或拖拽文件到此上传
                </p>
                <p class="ant-upload-hint">仅限.pdf 格式文件上传</p>
              </a-upload-dragger>
            </div>
          </div>
        </div>
      </div>
      <div id="submit">
        <a-button @click="cancel">取消</a-button>
        <a-button type="primary" id="btn" @click="submit">提交</a-button>
      </div>
    </a-card>
  </page-header-wrapper>
</template>

<script>
import storage from 'store';
    import { ACCESS_TOKEN } from '@/store/mutation-types';
  import {
    Modal,
    message
  } from 'ant-design-vue'
  import {
    getUrlKey,
    imgUpload,
    prefix,
    decrypt
  } from '@/utils'
  import {
    _editDoc,
    _getDocInfo
  } from '@/api/article'
  var disabled = true
  export default {
    name: '',
    data() {
      return {
        prefix: prefix,
        imgUpload: imgUpload,
        form: {
          id: '',
          type: '1',
          content: '',
          title: ''
        },
        fileList: [],
        dataObj:{
            
        },
        headers:{
            token:storage.get(ACCESS_TOKEN)
        },
      }
    },
    created() {
      this.form.id = getUrlKey('id') || '';
      if (this.form.id) {
        this.getInfo();
      }
    },
    methods: {
      handleChange(info) {
        //console.log('info', info);
        const status = info.file.status;
        this.fileList = [...info.fileList].slice(-1);
        if (status !== 'uploading') {}
        if (status == 'removed') {
          this.form.content = '';
        }
        if (status === 'done') {
            if(info.file.response.code==0){
                let enData = info.file.response.data;
                enData = decrypt(enData);
                this.form.content = enData.path;
            }else if(info.file.response.code==-98){
                this.$message.error(`登录已过期,请重新登录`);
                setTimeout(_=>{
                    localStorage.clear();
                    location.reload();
                },1200)
            }else {
                this.$message.error(info.file.response.msg);
                this.fileList = [];
            }
        } else if (status === 'error') {
            this.$message.error('上传失败');
            this.upLoading = false;
            this.fileList = [];
        }
      },
      getInfo() {
        let params = {
          id: this.form.id
        }
        _getDocInfo(params).then(res => {
          if (res.code == 200) {
            let data = res.data
            this.form.title = data.title
            this.form.content = data.content
            if (data.content) {
              this.fileList = [{
                uid: '-1',
                name: '附件',
                url: this.prefix + data.content,
                path: data.content,
              }]
            }
          }
        })
      },
      submit() {
        let params = {
          id: this.form.id,
          content: this.form.content,
          title: this.form.title,
          type: this.form.type
        }
        if (!this.form.title) {
          this.$message.info('请输入标题')
          return false
        } else if (!this.form.content) {
          this.$message.info('请上传文件')
          return false
        }
        if (disabled) {
          disabled = false
          _editDoc(params).then(res => {
            if (res.code == 200) {
              this.$message.success(this.form.id ? '编辑成功' : '新增成功')
              this.reset()
              setTimeout(_ => {
                this.$router.go(-1)
                disabled = true
              }, 500)
            } else {
              disabled = true
              this.$message.error(res.msg)
            }
          })
        }
      },
      cancel() {
        this.reset();
        this.$router.go(-1);
      },
      reset() {
        this.form.id = '';
        this.form.title = '';
        this.form.content = '';
      },
      reback() {
        this.$router.go(-1)
      }
    }
  }
</script>


<style scoped>
  #submit {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }

  #btn {
    margin-left: 20px;
  }

  .title {
    height: 22px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(51, 51, 51, 1);
    line-height: 22px;
    margin-bottom: 20px;
    padding: 0px;
  }

  .main {
    margin-bottom: 25px;
  }
</style>
